var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container-ty" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "start", gutter: 3 } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, lg: 12 } },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "index-md-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("img", {
                            attrs: { src: require("../../assets/daiban.png") },
                          }),
                          _vm._v(
                            "\n            我的待办【" +
                              _vm._s(_vm.dataSource1.length) +
                              "】\n          "
                          ),
                        ]
                      ),
                      _c("div", { attrs: { slot: "extra" }, slot: "extra" }, [
                        _vm.dataSource1 && _vm.dataSource1.length > 0
                          ? _c(
                              "a",
                              {
                                attrs: { slot: "footer" },
                                on: { click: _vm.goPage },
                                slot: "footer",
                              },
                              [
                                _vm._v("更多 "),
                                _c("a-icon", {
                                  attrs: { type: "double-right" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("a-table", {
                        ref: "table1",
                        class: "my-index-table tytable1",
                        attrs: {
                          size: "small",
                          rowKey: "id",
                          columns: _vm.columns,
                          dataSource: _vm.dataSource1,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ellipsisText",
                            fn: function (text) {
                              return [
                                _c("j-ellipsis", {
                                  attrs: {
                                    value: text,
                                    length: _vm.textMaxLength,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "dayWarnning",
                            fn: function (text, record) {
                              return [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "22px" },
                                  attrs: {
                                    type: "bulb",
                                    theme: "twoTone",
                                    twoToneColor: _vm.getTipColor(record),
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c("a", { on: { click: _vm.handleData } }, [
                                  _vm._v("办理"),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, lg: 12 } },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "index-md-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("img", {
                            attrs: { src: require("../../assets/zaiban.png") },
                          }),
                          _vm._v(
                            "\n            我的在办【" +
                              _vm._s(_vm.dataSource2.length) +
                              "】\n          "
                          ),
                        ]
                      ),
                      _c("div", { attrs: { slot: "extra" }, slot: "extra" }, [
                        _vm.dataSource2 && _vm.dataSource2.length > 0
                          ? _c(
                              "a",
                              {
                                attrs: { slot: "footer" },
                                on: { click: _vm.goPage },
                                slot: "footer",
                              },
                              [
                                _vm._v("更多 "),
                                _c("a-icon", {
                                  attrs: { type: "double-right" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("a-table", {
                        ref: "table2",
                        class: "my-index-table tytable2",
                        attrs: {
                          size: "small",
                          rowKey: "id",
                          columns: _vm.columns,
                          dataSource: _vm.dataSource2,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ellipsisText",
                            fn: function (text) {
                              return [
                                _c("j-ellipsis", {
                                  attrs: {
                                    value: text,
                                    length: _vm.textMaxLength,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "dayWarnning",
                            fn: function (text, record) {
                              return [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "22px" },
                                  attrs: {
                                    type: "bulb",
                                    theme: "twoTone",
                                    twoToneColor: _vm.getTipColor(record),
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c("a", { on: { click: _vm.handleData } }, [
                                  _vm._v("办理"),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("div", { staticStyle: { height: "5px" } }),
              ]),
              _c(
                "a-col",
                { attrs: { sm: 24, lg: 12 } },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "index-md-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("img", {
                            attrs: { src: require("../../assets/guaz.png") },
                          }),
                          _vm._v(
                            "\n            我的挂账【" +
                              _vm._s(_vm.dataSource4.length) +
                              "】\n          "
                          ),
                        ]
                      ),
                      _c("a-table", {
                        ref: "table4",
                        class: "my-index-table tytable4",
                        attrs: {
                          size: "small",
                          rowKey: "id",
                          columns: _vm.columns,
                          dataSource: _vm.dataSource4,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ellipsisText",
                            fn: function (text) {
                              return [
                                _c("j-ellipsis", {
                                  attrs: {
                                    value: text,
                                    length: _vm.textMaxLength,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "dayWarnning",
                            fn: function (text, record) {
                              return [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "22px" },
                                  attrs: {
                                    type: "bulb",
                                    theme: "twoTone",
                                    twoToneColor: _vm.getTipColor(record),
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c("a", { on: { click: _vm.handleData } }, [
                                  _vm._v("办理"),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, lg: 12 } },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "index-md-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("img", {
                            attrs: { src: require("../../assets/duban.png") },
                          }),
                          _vm._v(
                            "\n            我的督办【" +
                              _vm._s(_vm.dataSource3.length) +
                              "】\n          "
                          ),
                        ]
                      ),
                      _c("a-table", {
                        ref: "table3",
                        class: "my-index-table tytable3",
                        attrs: {
                          size: "small",
                          rowKey: "id",
                          columns: _vm.columns,
                          dataSource: _vm.dataSource3,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ellipsisText",
                            fn: function (text) {
                              return [
                                _c("j-ellipsis", {
                                  attrs: {
                                    value: text,
                                    length: _vm.textMaxLength,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "dayWarnning",
                            fn: function (text, record) {
                              return [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "22px" },
                                  attrs: {
                                    type: "bulb",
                                    theme: "twoTone",
                                    twoToneColor: _vm.getTipColor(record),
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c("a", { on: { click: _vm.handleData } }, [
                                  _vm._v("办理"),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }